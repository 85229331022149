import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';
import { SketchPicker } from 'react-color'; // Import a color picker library
import jsonData from './cluster_data.json';
import './DataVisualization.css';

const DataVisualization = () => {
    const initialClusters = Array.from(new Set(jsonData.map(d => d.cluster_identities)));
    const initialColors = initialClusters.reduce((acc, cluster) => {
        acc[cluster] = d3.schemeCategory10[Math.floor(Math.random() * 10)]; // Assign a random color
        return acc;
    }, {});

    const [highlightClusters, setHighlightClusters] = useState(initialClusters);
    const [colors, setColors] = useState(initialColors);
    const [showColorPicker, setShowColorPicker] = useState({});

    useEffect(() => {
        const width = 800;  // SVG width
        const height = 800; // SVG height

        const svg = d3.select('#visualization')
            .attr('width', width)
            .attr('height', height);

        // Clear the SVG before rendering
        svg.selectAll('*').remove();

        const g = svg.append('g');

        // Define a scaling factor
        const xScaleFactor = 100; // Adjust this factor as needed
        const yScaleFactor = 100; // Adjust this factor as needed
        const circleRadius = 1; // Circle radius

        // Plot the points with scaling applied
        g.selectAll('circle')
            .data(jsonData)
            .enter()
            .append('circle')
            .attr('cx', d => d.x * xScaleFactor)
            .attr('cy', d => d.y * yScaleFactor)
            .attr('r', circleRadius)
            .attr('fill', d => {
                const clusterIndex = highlightClusters.indexOf(d.cluster_identities);
                return clusterIndex !== -1 ? colors[d.cluster_identities] : 'lightgrey';
            });

        // Apply zoom behavior to the group element, not the entire SVG
        const zoomBehavior = d3.zoom()
            .on('zoom', (event) => {
                g.attr('transform', event.transform);
            });

        svg.call(zoomBehavior);

    }, [highlightClusters, colors]);

    const handleButtonClick = (cluster) => {
        setHighlightClusters(prev => {
            if (prev.includes(cluster)) {
                // If the cluster is already selected, remove it
                return prev.filter(c => c !== cluster);
            } else {
                // Otherwise, add it to the selection
                return [...prev, cluster];
            }
        });
    };

    const handleColorChange = (cluster, color) => {
        setColors(prevColors => ({
            ...prevColors,
            [cluster]: color.hex
        }));
    };

    const toggleColorPicker = (cluster) => {
        setShowColorPicker(prev => ({
            ...prev,
            [cluster]: !prev[cluster]
        }));
    };

    return (
        <div className="data-visualization-container">
            <div className="button-panel">
                {initialClusters.map((cluster) => (
                    <div key={cluster} className="color-picker-container">
                        <button 
                            onClick={() => handleButtonClick(cluster)}
                            style={{ backgroundColor: highlightClusters.includes(cluster) ? colors[cluster] || 'white' : 'white' }}
                            className="cluster-button"
                        >
                            {cluster}
                        </button>
                        <button 
                            onClick={() => toggleColorPicker(cluster)}
                            className="custom-color-button"
                        >
                            Custom Color
                        </button>
                        {showColorPicker[cluster] && (
                            <SketchPicker
                                color={colors[cluster] || '#ffffff'}
                                onChangeComplete={(color) => handleColorChange(cluster, color)}
                                className="color-picker"
                            />
                        )}
                    </div>
                ))}
            </div>
            <div className="visualization-panel">
                <svg id="visualization"></svg>
            </div>
        </div>
    );
}

export default DataVisualization;
