import React from 'react';

function JoinUsPage() {
  return (
    <div className="join-us-page">
      <h1>We are hiring</h1>
      
      <div className="job-section">
        <h2 className="job-title">Machine Learning Researcher (3)</h2>
        <p className="job-location">San Francisco Bay Area - Full-time</p>
        <p>
          We are seeking highly skilled AI Scientists to join our team. The ideal
          candidate will possess deep knowledge of state-of-the-art generative AI
          models and computer vision. Responsibilities include training large
          language models and collaborating closely with data scientists to develop
          cutting-edge applications. Applicants should hold a Bachelor’s degree or
          higher in Computer Science, Mathematics, or a related field. While not
          required, a strong preference will be given to candidates who demonstrate
          a passion for biomedical sciences and exhibit an innovative mindset.
        </p>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSeHJHDnW7ZfRZo7YeWnTmFiEtDAAzBGTv-rpTqlIuvnseXAzQ/viewform?usp=sharing" className="apply-link">Please submit your application here.</a>
      </div>
      
      <div className="job-section">
        <h2 className="job-title">Software Engineer (3)</h2>
        <p className="job-location">San Francisco Bay Area - Full-time</p>
        <p>
          Candidates are expected to have a proven ability in developing, testing, and troubleshooting
          complex software systems. They should possess at least two years of experience as a software
          and networking platforms engineer, complemented by five years of combined professional and
          academic programming experience in Java, Python, and C++. Preferred qualifications include
          holding a Bachelor’s degree or higher in software engineering and having experience in developing
          web applications across various frameworks. Ideal candidates will exhibit an innovative mindset
          and a strong passion for advancing technology in the biomedical field.
        </p>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdsLd4Bl_mjYzH4mDqjOVYHvpUcccNKjkPk-5Rnf48-9-7ogA/viewform?usp=sharing" className="apply-link">Please submit your application here.</a>
      </div>
    </div>
  );
}

export default JoinUsPage;


