import React from 'react';

function SpatialOmics() {
  const handleButtonClick = (imageId) => {
    const imgContainer = document.getElementById('image-container');
    imgContainer.innerHTML = `<img src="/images/${imageId}.png" alt="Image ${imageId}" class="responsive-image" />`;
  };

  return (
    <div className="spatialomics-page">
      <h1>Single-cell Spatial Transcriptomics</h1>
      <div className="button-container">
        <button onClick={() => handleButtonClick('5050')}>5050</button>
        <button onClick={() => handleButtonClick('5065')}>5065</button>
        <button onClick={() => handleButtonClick('5163')}>5163</button>
        <button onClick={() => handleButtonClick('5173')}>5173</button>
        <button onClick={() => handleButtonClick('5212')}>5212</button>
        <button onClick={() => handleButtonClick('5267')}>5267</button>
      </div>
      <div id="image-container">
        {/* The image will be displayed here */}
      </div>
    </div>
  );
}

export default SpatialOmics;
