import React from 'react';
import { useNavigate } from 'react-router-dom';
import bioP1 from './BioP1.png'; // Ensure the path to your image is correct

function HomePage() {
  const navigate = useNavigate();

  const handleDataVisualizationClick = () => {
    navigate('/data-visualization');
  };

  return (
    <div className="homepage">
      <div className="image-container">
        <img src={bioP1} alt="BioP1" className="center-image" />
        <div className="centered-text">AI FOR BIOMEDICAL SCIENCES</div>
      </div>
      <div className="homepage-text">
        <p>
          We are a group of scientists and engineers located in Northern California, United States.
        </p>
        <p>
          Our mission is to empower researchers in the biomedical field with state-of-the-art machine learning tools.
        </p>
      </div>
      <div className="button-container">
        <button onClick={handleDataVisualizationClick} className="navigate-button">
          Go to Data Visualization
        </button>
      </div>
    </div>
  );
}

export default HomePage;


