import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import './App.css';
import icon from './icon.png';
import HomePage from './HomePage';
import ToolsPage from './ToolsPage';
import JoinUsPage from './JoinUsPage';
import SpatialOmics from './SpatialOmics';
import DataVisualization from './DataVisualization'; // Import the new DataVisualization page

function App() {
  return (
    <Router>
      <div className="App">
        <nav className="semi-layout-header">
          <div className="header-left">
            <img src={icon} alt="BioChemML Icon" className="header-icon" />
            <NavLink to="/" className="nav-link">BioChemML</NavLink>
          </div>
          <div className="header-right">
            <NavLink to="/" className="nav-link">Home</NavLink>
            <NavLink to="/tools" className="nav-link">Tools</NavLink>
            <NavLink to="/spatial-omics" className="nav-link">Spatial Omics</NavLink>
            <NavLink to="/data-visualization" className="nav-link">Data Visualization</NavLink> {/* Updated link */}
            <NavLink to="/join-us" className="nav-link">Join Us</NavLink>
            <a href="mailto:support@biochemml.com" className="support-email">support@biochemml.com</a>
          </div>
        </nav>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/tools" element={<ToolsPage />} />
          <Route path="/spatial-omics" element={<SpatialOmics />} />
          <Route path="/data-visualization" element={<DataVisualization />} /> {/* New route */}
          <Route path="/join-us" element={<JoinUsPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
