function ToolsPage() {
  return (
    <div className="tools-page">
      <div className="video-row">
        <iframe
          style={{ border: 'none', width: '33%', height: '300px' }}
          src="https://www.youtube.com/embed/3-RSjPakGH4"
          title="Bioinfo Copilot Video 1"
          allowFullScreen>
        </iframe>
        <iframe
          style={{ border: 'none', width: '33%', height: '300px' }}
          src="https://www.youtube.com/embed/sA5zUwWhyXE"
          title="Bioinfo Copilot Video 2"
          allowFullScreen>
        </iframe>
        <iframe
          style={{ border: 'none', width: '33%', height: '300px' }}
          src="https://www.youtube.com/embed/2Qnd9Pj2XeM"
          title="Bioinfo Copilot Video 3"
          allowFullScreen>
        </iframe>
      </div>
      <p>
        Data analysis is the rate-limiting step for transcriptome projects. We hope this software can allow biologists and bioinformaticians to analyze their data with prompts, thus expediting the progress of projects.
      </p>
      {/* Add any additional content or styling needed for this page */}
      To request access, please click the link <a href="https://docs.google.com/forms/d/e/1FAIpQLSeBWAjt6K3A-ruyv388IXA-i-90GJI8EDtJukj8KeP2xLkygg/viewform?usp=sharing">here</a>.
    </div>
  );
}

export default ToolsPage;
